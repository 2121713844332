import { post } from '@core/network';
import ApiHost from './api-config';

/**
 * 获取灰度配置
 *
 * http://app-gateway-test.zmlearn.com/kids/api/grayApi/swagger-ui.html#!/gray-endpoint/findBatchGrayEffectByCodeAndParamUsingPOST
 * @param {Array<string>} planCodeList 灰度名称列表
 * @param {object} map 灰度查询参数
 */
export function getGrayConfigBatch({
	planCodeList,
	map = {},
	grayCodeList,
}: {
	planCodeList?: string[];
	map: Record<string, number | string>;
	grayCodeList?: string[];
}) {
	return post({
		url: `${ApiHost.appGateWay}/kids/api/grayApi/toc/gray/findMany`,
		data: {
			grayCodeList,
			planCodeList,
			map,
		},
	});
}
