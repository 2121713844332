type BuildType = 'prod' | 'uat' | 'test';

interface IlinkMap {
	[key: string]: {
		prod: string;
		uat: string;
		test: string;
	};
}

/** 接口配置对象 */
const hostConfig: StringMap = {
	clientId: '18bd1e36-45e1-45a7-8102-7c6af21a28f2',
	clientSecret: 'SOKnh1IO',
};

const setConfig = (type: BuildType) => {
	if (!['prod', 'uat', 'test'].includes(type)) {
		type = 'test';
	}

	const linkMap: IlinkMap = {
		// 少儿专用网关域名，默认的接口请求域名
		appGateWay: {
			test: 'https://ac-air-class-gateway-acfat-alhz.air-learn.com',
			uat: 'https://air-class-gateway-fat-alhz.inzm.com',
			prod: 'https://a-app-gateway.air-class.com',
		},
		homeUri: {
			test: 'https://ac-pc-test-chat.air-class.com',
			uat: 'https://chat-air-class.uat.zmops.cc',
			prod: 'https://ac-pc-chat.air-class.com',
		},
	};

	Object.keys(linkMap).forEach((key) => {
		hostConfig[key] = linkMap[key][type];
	});
};

// 默认根据域名配置地址，可以通过 window.resetConfig 手动切换配置，用于打包后测试不同环境代码的执行结果
const buildType = process.env.BUILD_TYPE as BuildType;
setConfig(buildType);

export default hostConfig;
