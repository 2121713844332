import { get, post } from '@core/network';
import ApiHost from './api-config';

/**
 * 获取灰度配置
 *
 * http://app-gateway-test.zmlearn.com/kids/api/grayApi/swagger-ui.html#!/gray-endpoint/findBatchGrayEffectByCodeAndParamUsingPOST
 * @param {Array<string>} planCodeList 灰度名称列表
 * @param {object} map 灰度查询参数
 */
export function getGrayConfigBatch({
	planCodeList,
	map = {},
	grayCodeList,
}: {
	planCodeList?: string[];
	map: Record<string, number | string>;
	grayCodeList?: string[];
}) {
	return post({
		url: `${ApiHost.appGateWay}/kids/api/grayApi/toc/gray/findMany`,
		data: {
			grayCodeList,
			planCodeList,
			map,
		},
	});
}

/**
 * 发送短信验证码
 * @param param0
 * @returns
 */
export const sendSMSCode = ({
	mobile,
	codeType,
	areaCode,
	options,
}: {
	mobile: number | string;
	areaCode: string;
	codeType: 'Login' | 'ResetPassword';
	options: Record<string, any>;
}) => {
	const { geetest_challenge, geetest_seccode, geetest_validate } = options || {};

	const url =
		codeType === 'Login'
			? `${ApiHost.appGateWay}/air-teacher-api/api/sms/global/sendValidateCodeByLogin`
			: `${ApiHost.appGateWay}/air-teacher-api/api/sms/global/sendValidateCodeByResetPassword`;
	return post({
		url,
		data: {
			challenge: geetest_challenge,
			validate: geetest_validate,
			seccode: geetest_seccode,
			areaCode,
			mobile,
		},
	});
};

/**
 * 学生短信验证码
 * @param param0
 * @returns
 */
export const sendSMSCodeStu = ({
	mobile,
	codeType,
	areaCode,
	options,
}: {
	mobile: number | string;
	areaCode: string;
	codeType: 'Login' | 'ResetPassword';
	options: Record<string, any>;
}) => {
	const { geetest_challenge, geetest_seccode, geetest_validate } = options || {};

	const url =
		codeType === 'Login'
			? `${ApiHost.appGateWay}/air-student-api/api/sms/global/sendValidateCodeByLogin`
			: `${ApiHost.appGateWay}/air-student-api/api/sms/global/sendValidateCodeByResetPassword`;
	return post({
		url,
		data: {
			challenge: geetest_challenge,
			validate: geetest_validate,
			seccode: geetest_seccode,
			areaCode,
			mobile,
		},
	});
};

/**
 * 短信验证码登陆
 * @param param0
 * @returns
 */
export const loginBySMSCode = ({
	areaCode,
	mobile,
	validateCode,
	roleType,
}: {
	areaCode: string;
	mobile: number | string;
	validateCode: string | number;
	roleType: RoleType;
}) => {
	const roleMapping = {
		'stu': `${ApiHost.appGateWay}/air-student-api/api/oauth/student/global/loginBySmsValidateCode`,
		'tch': `${ApiHost.appGateWay}/air-teacher-api/api/oauth/teacher/loginBySmsValidateCode`,
		'sales': `${ApiHost.appGateWay}/air-teacher-api/api/oauth/seller/loginBySmsValidateCode`,
	};
	const url = roleMapping[roleType];


	return post({
		url,
		data: {
			areaCode,
			mobile,
			validateCode,
		},
		headers: {
			channel: process.platform === 'darwin' ? 'pc_mac' : 'pc_win'
		}
	});
};

/**
 * 老师账号密码登陆
 * @returns
 */
export const logByMobileRSA = (msg: string) => {
	return post({
		url: `${ApiHost.appGateWay}/air-teacher-api/api/oauth/teacher/loginEncrypt`,
		data: {
			msg,
		},
	});
};

/**
 * 销售账号密码登陆
 * @returns
 */
export const logByMobileRSASales = (msg: string) => {
	return post({
		url: `${ApiHost.appGateWay}/air-teacher-api/api/oauth/seller/loginEncrypt`,
		data: {
			msg,
		},
	});
};


/**
 * 学生登录
 * @returns
 */
export const logByMobileRSAStu = (msg: string) => {
	return post({
		url: `${ApiHost.appGateWay}/air-student-api/api/oauth/student/global/loginEncrypt`,
		data: {
			msg,
		},
	});
};

/**
 * 获取极验配置
 * @returns
 */
export const getGeetestConfig = (roleType: RoleType) => {
	return post({
		url: `${ApiHost.appGateWay}/${roleType === 'stu' ? 'air-student-api' : 'air-teacher-api'}/api/geetest/api1`,
	});
};

/**
 * 获取RSA 秘钥key
 * @returns
 */
export const getRsaPubKey = (roleType: RoleType) => {
	if (roleType === 'stu') {
		return get({
			url: `${ApiHost.appGateWay}/air-student-api/api/oauth/rsaPubKey`,
		});
	}
	return post({
		url: `${ApiHost.appGateWay}/air-teacher-api/api/oauth/rsaPubKey`,
	});
};

/**
 * 重置密码
 * @returns
 */
export const postResetPassword = ({
	areaCode,
	password,
	validateCode,
	mobile,
	roleType,
}: {
	areaCode: string;
	password: string;
	validateCode: string;
	mobile: string;
	roleType: RoleType;
}) => {
	const url =
		roleType === 'stu'
			? `${ApiHost.appGateWay}/air-student-api/api/user/global/resetPassword`
			: `${ApiHost.appGateWay}/air-teacher-api/api/oauth/resetPassword`;

	return post({
		url,
		data: {
			areaCode,
			password,
			mobile,
			username: mobile,
			validateCode,
		},
	});
};


/**
 * 获取地理位置
 * @returns
 */
export const getPlaceIp = () => {
	const isProd: boolean = process.env.BUILD_TYPE === 'prod'
	const baseUrl: string =
		isProd ?
			'https://a-app-gateway.air-class.com' :
			'https://air-class-gateway-fat-alhz.zmaxis.com' //外网可访问的测服地址

	return get({
		url: `${baseUrl}/air-ips/api/ip`
	});
};
