import hostConfig from '@services/api-config';
import { getRsaPubKey, logByMobileRSA, logByMobileRSAStu, logByMobileRSASales } from '@services/login';
// 加密函数
const encrypt = new window.JSEncrypt();

/**
 * 密码登陆
 * @param mobile
 * @param pwd
 */
export const handleRSALogin = async (mobile: string, password: string, areaCode: string, userType: RoleType) => {
	const pubKey = await getRsaPubKey(userType);
	encrypt.setPublicKey(pubKey);
	const msg = encrypt.encrypt(
		JSON.stringify({
			areaCode,
			mobile,
			password,
			timestamp: `${+new Date()}`,
		})
	);
	const mapping = {
		'stu': logByMobileRSAStu,
		'tch': logByMobileRSA,
		'sales': logByMobileRSASales,
	}

	return mapping[userType](msg);

};

/**
 * 打开登录窗口
 * @param state
 * @param role
 * @param basicInfo
 * @param loginData
 */
export const handleAfterLogin = async (role: RoleType, basicInfo: Record<string, any>) => {
	const { ipcRenderer } = window.require('electron');
	ipcRenderer.send('afterLogin', {
		url: `${hostConfig.homeUri}/${role}`,
		basicInfo: JSON.stringify(basicInfo),
	});
};


export const handleInitWin = async () => {
	const { ipcRenderer } = window.require('electron');
	ipcRenderer.send('setContentSize', {
		width: 960,
		height: 706
	})
}

/**
 * 点击退出应用
 */
export const forceAppClose = () => {
	let remote = window.require('electron')?.remote;
	if (!remote) {
		try {
			remote = window.require('@electron/remote');
		} catch (error) {
			return
		}
	}
	if (!remote) return;
	const windowManager = remote.require('./core/window-manager');
	windowManager.close('Login');
	remote.app.quit();
};

export const HIDE_APP = () => {
	const { ipcRenderer } = window.require('electron');
	ipcRenderer.send('hide-window')
}
