import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ConnectState } from 'states/connect';

import { forceAppClose } from '@utils/login';
type AppContainerPorps = {
	children: ReactElement;
	isV2: boolean;
};

/**
 * 全局容器
 * @param props
 * @returns
 */
export default function AppContainer(props: AppContainerPorps) {
	const { children, ...rest } = props;
	const local = useSelector((state: ConnectState) => state.app.local);
	return (
		<>
			{
				rest.isV2 ? (
					<div className='container-v2'>
						{React.cloneElement(children, { local, ...rest })}
					</div>
				) : (
					<div className="container">
						<i className="close-btn" onClick={forceAppClose} />
						{React.cloneElement(children, { local, ...rest })}
					</div>
				)
			}
		</>
	);
}
