import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageLoader from 'components/page-loader';
import { handleInitWin } from '@utils/login';

const PageHome = PageLoader({
	loader: () => import(/* webpackChunkName: "page-login" */ './main'),
	onEnter: () => {
		handleInitWin();
	},
});

// 页面入口
export default (props: RouteComponentProps) => <PageHome {...props} />;
