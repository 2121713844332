import { Effect, State } from 'jumpstate';
import Logger from '@core/logger';
import { getGrayConfigBatch } from '@services/global';
import { sendLog } from '@utils/core/track';
import { getDefaultLanguage } from '@fe/i18n';

const logger = Logger.factory('state/global');

export type GlobalStoreState = {
	grayConfigs: Record<string, boolean>;
	appConfig: Record<string, any>;
	local: string;
};

/**
 * 全局数据处理
 */
const globalStore = State('app', {
	initial: {
		grayConfigs: {}, // 全局灰度配置
		appConfig: {},
		local: getDefaultLanguage(),
	},
	/**
	 * 更新全局数据
	 */
	setCommonData(state: GlobalStoreState, payload: GlobalStoreState) {
		logger.info('global data change', state, payload);
		return {
			...state,
			...payload,
		};
	},
	/**
	 * 设置语言
	 * @param state
	 * @param payload
	 * @returns
	 */
	setLocal(state: GlobalStoreState, payload: string) {
		logger.info('global local change', state.local, payload);
		return {
			...state,
			local: payload,
		};
	},
});

/**
 * 应用级别配置
 */
export const loadAppConfig = Effect('loadAppConfig', async () => {
	const res = await getGrayConfigBatch({
		grayCodeList: ['GRAY_WORLD_OUTLOOK'],
		planCodeList: ['PLAN_PLATFORM_UPDATE'],
		map: {
			platform: window.process.platform,
			role: 'student',
		},
	}).catch((err) => {
		sendLog({
			eventId: 'fetch_appconfig_erro',
			eventParam: {
				err,
			},
		});
		return {};
	});
	globalStore.setCommonData({
		appConfig: res,
		newWorldOutlook: !!res['GRAY_WORLD_OUTLOOK'],
	});
	return res;
});
export default globalStore;
