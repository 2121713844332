import React, { ReactNode } from 'react';
import { Switch, Route, Router, RouteComponentProps } from 'react-router-dom';
import { history } from '@store/history';
import { LOGIN, STULOGINV2 } from 'constants/url';
import AppContainer from 'containers/app-container';

import Login from 'pages/login';
import StuLoginV2 from 'pages/stu-login-v2';

/**
 * 路由配置表 [new, old]
 */
const URL_MAP = {
	[LOGIN]: Login,
	[STULOGINV2]: StuLoginV2,
} as Record<string, (props: RouteComponentProps) => ReactNode>;

export default () => (
	<Router history={history}>
		<AppContainer isV2={window.location.pathname === STULOGINV2}>
			<Switch>
				{Object.keys(URL_MAP).map((uri: string) => {
					return <Route key={uri} exact path={uri} render={URL_MAP[uri]} />;
				})}
			</Switch>
		</AppContainer>
	</Router>
);
