import Logger from './logger';
import { getState } from 'jumpstate';

const logger = Logger.factory('track');
let lastUserId: number;

export interface SendEventParam {
	eventId: string;
	eventParam?: Record<string, any>;
	eventValue?: number;
	eventType?: number;
	logDesc?: string;
	code?: string;
}

/* 初始化埋点SDK */
export function initZMSDK() {
	const JSSDK = window.ZM_JSSDK;
	if (!JSSDK) {
		return;
	}

	// 配置sdk参数
	JSSDK.setConfig({
		environment: process.env.BUILD_TYPE === 'test' ? 'fat' : process.env.BUILD_TYPE,
		logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
		history: true,
		trackWhiteList: [
			'//client-gateway.uat.zmops.cc',
			'https://test-chat-gateway.zmlearn.com',
			'//ac-chat.air-class.com',
			'https://app-gateway.zmlearn.com',
		],
		urlParams: true, // pv 埋点自动解析 qs
	});

	// 配置上报公共属性
	JSSDK.setDefaults({
		appId: process.env.APPID,
	});
}

/**
 * 新事件打点
 * @param {[string]} eventId [事件id]
 * @param {[number]} eventType [事件类型, 0: 事件次数 / 1: 事件时长] 非必填
 * @param {[number]} eventValue [事件值] 非必填
 * @param {[object]} eventParam [打点参数]
 */
export function sendEvent({ eventId, eventParam = {}, eventValue = 2, eventType, logDesc }: SendEventParam) {
	const JSSDK = window.ZM_JSSDK;
	if (!JSSDK) {
		return;
	}

	// 处理错误信息堆栈
	if (eventParam && eventParam.err) {
		const error = eventParam.err;
		eventParam.err = error instanceof Error ? { message: error.message || '出错了,无错误信息！', stack: error.stack } : error;
	}

	if (JSSDK.sendEvent) {
		logger.info('track event name', eventId, eventParam, eventValue, eventType, logDesc);
		JSSDK.sendEvent({ eventId, eventType, eventValue, eventParam });
	}
}

/**
 * 技术埋点
 * @param {[string]} eventId [事件id]
 * @param {[object]} eventParam [打点参数]
 */
export function sendLog({ eventId, code, eventParam = {}, logDesc }: SendEventParam) {
	const JSSDK = window.ZM_JSSDK;
	if (!JSSDK) {
		return;
	}

	// 处理错误信息堆栈
	if (eventParam && eventParam.err) {
		const error = eventParam.err;
		eventParam.err = error instanceof Error ? { message: error.message || '出错了,无错误信息！', stack: error.stack } : error;
	}

	const { userId, paid } = getState().user;
	const combineParam = {
		...eventParam,
		is_pay: paid ? 'Y' : 'N',
		userId,
	};

	// 登录成功后，设置 userId
	if (JSSDK.setDefaults && !lastUserId && userId) {
		JSSDK.setDefaults({ userId });
	}
	lastUserId = userId;

	logger.info('track log name', eventId, combineParam, logDesc);
	JSSDK.sendLog({
		code: code || eventId,
		message: combineParam,
		userId,
		level: 'info',
	});
}
