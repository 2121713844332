import './styles/global.scss';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import Routes from './routers';
import { configureStore } from './store';
import globalStore from 'states/app';
import { initZMSDK } from '@core/track';
import localeLoaders from './locales';
import i18n from '@fe/i18n';

const rootEle = document.getElementById('app');

// store状态
const store: any = configureStore();

/** 初始化操作，优先级最高 */
function init() {
	initZMSDK(); // 初始化埋点参数
}

// 初始化相关sdk
init();

i18n.setLocaleLoaders(localeLoaders);
// 系统语言变化
i18n.on('localechange', (locale, prev) => {
	globalStore.setLocal(locale);
});

i18n.ready().then(() => {
	render(
		<Provider store={store}>
			<Routes />
		</Provider>,
		rootEle
	);
});
